<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<modal title="Additional Filters" handler="true" :cssClass="'modal-xl'" @modalHandler="onCancel"
  :isLoading="isLoading || isSegmentLoading">
  <template v-slot:content>
    <div id="app" class="dashboard-right-inner">
      <div class="max-height-100-190">
        <form>
          <div class="create-update-segment-modal-component-wrap" :class="selectedSegment?.id ? 'disabled' : '' ">
            <CreateUpdateSegment :onConfirm="onConfirmHit" :isFromSegmentModal="isFromSegmentModal" :additionalFilterData="additionalFilterData"
              :typeOfAction="typeOfAction" :isFromFlowBuilder="isFromFlowBuilder"
              :selectedSegment="selectedSegment" ref="childComponent"
              />
          </div>
        </form>
      </div>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton @click="onCancel" :class="'flex-none'">Cancel</kbutton>
      </div>
      <kbutton @click="updateParent" :class="'ms-3'" :theme-color="'primary'">
        {{isAnySegmentSelected ? "Update" : "Save" }} Filter</kbutton>
    </div>
  </template>
</modal>