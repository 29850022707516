<template src="./CreateUpdateSegmentModal.html"></template>
<style lang="scss">
@import "./CreateUpdateSegmentModal.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import AppDropdownGroup from "../../common/AppDropdownGroup/AppDropdownGroup.vue";
import AddSegmentModel from "../../../models/segments/addSegmentModel.ts";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppLoader from "../../common/AppLoader/AppLoader";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import modal from "../AppModal/AppModal.vue";
import {
  getSegmentConfigConditions,
  addSegment,
  updateSegment,
  getSegmentDropdownList,
  getSegment,
} from "@/services/clubDetails/segments.service";
import dropdownlistWithGroup from "../../common/dropdownlistWithGroup/dropdownlistWithGroup.vue";

import AppSnackBar from "../../common/AppSnackBar/AppSnackBar.vue";
import CreateUpdateSegment from "@/components/clubDetails/audience/segments/CreateEditSegment/CreateUpdateSegment.vue";
export default {
  name: "CreateUpdateSegmentModal",
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    "k-input": Input,
    AppDropdownGroup,
    datePicker: DatePicker,
    kdatetime: DateTimePicker,
    AppLoader,
    AppSnackBar,
    Breadcrumb,
    dropdowntree: DropDownTree,
    modal,
    dropdownlistWithGroup,
    CreateUpdateSegment
  },
  props: {
    onConfirm: {
      type: Function,
      default: () => { },
    },
    onCancel: {
      type: Function,
      default: () => { },
    },
    additionalFilterData: {
      type: Object,
      default: () => { },
    },
    typeOfAction: {
      type: String,
      default: "create",
    },
    isFromFlowBuilder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({

    selectedSegment: null,
    isAnySegmentSelected: false,
    isNameFieldVisible: true,

    sourceAndOr: [
      { id: "AND", text: "All" },
      { id: "OR", text: "Any" },
    ],
    sourceAndOrValue: "",
    mapConfig: [],
    addSegmentsModel: new AddSegmentModel(),
    payloadAddSegmentsModel: new AddSegmentModel(),
    isFirstColumn: false,
    isSecondColumn: false,
    nextCondition: "",
    configGroup: 0,
    isLoading: false,
    isGetSegmentLoading: false,
    index: 0,
    isNotify: false,
    notifyMessage: "Success",
    notifyType: "success",
    segmentChildConditionGroupDropdown: [],
    segmentChildConditionWhereDropdown: [],
    getSegmentConfigConditionsData: {},
    dayHoursWeeksYearDropDown: [
      { id: "Hour", text: "Hours" },
      { id: "Day", text: "Days" },
      { id: "Week", text: "Weeks" },
      { id: "Month", text: "Months" },
      { id: "Year", text: "Years" },
    ],
    dayHoursWeeksYearDropDownValue: [],
    dayHoursWeeksYearDropDownValueChnage: [],
    isWhereFirstValueDp: [],
    isWhereSecondValueDp: [],
    titleStr: "",
    modalTitle: "",
    isModalLoad: false,
    isFromSegmentModal: true
  }),

  mounted() {

    this.isAnySegmentSelected = this.$route.query.segmentId ? true : false;
    this.isNameFieldVisible - this.isFromFlowBuilder ? true : false;

    // this.getSegmentConfigConditionsFunc();
    // this.sourceAndOrValue = this.sourceAndOr[0];
    // this.getSegmentConfigs();

    // if (this.typeOfAction == "create") {
    //   this.addNewConditions(0);
    //   this.titleStr = "Create";
    // }
    // if (this.typeOfAction == "update") {
    //   this.getSegment();
    //   this.titleStr = "Edit";
    // }
    // this.addSegmentsModel.clubId = this.$route.params.id;
    // this.addSegmentsModel.entityType = 2;
    // this.addSegmentsModel.conditionType = this.sourceAndOr[0].id;
  },

  computed: {
    segmentLists() {
      return this.$store.state.segments.segmentListForDropdown
    },
    segmentsConfigsData() {
      return this.$store.state.segments.segmentsConfigs;
    },
    getSegmentConfigConditions() {
      return this.$store.state.segments.getSegmentConfigConditions;
    },
    segmentStoreData() {
      return this.$store.state.segments.segmentData;
    },
    isModalOpenForCreateSegment() {
      return this.$store.state.segments.segmentModalOpenClose;
    },
  },

  methods: {
    onConfirmHit(value) {
      console.log(value, 'onConfirmHit')
      this.onConfirm({
        segmentId: value.segmentId,
        segmentName: value.segmentName,
      });
    },
    updateParent() {
      this.$refs.childComponent.addSegment();
    },
  },
};
</script>

<style></style>
