<template src="./CreateUpdateSegment.html"></template>

<style lang="scss">
@import "./CreateUpdateSegment.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import AppDropdownGroup from "../../../../common/AppDropdownGroup/AppDropdownGroup";
import CreateUpdateSegmentModal from "../../../../common/CreateUpdateSegmentModal/CreateUpdateSegmentModal.vue";
import PassMapModel from "../../../../../models/digitalPass/PassMapModel";
import AddSegmentModel from "../../../../../models/segments/addSegmentModel.ts";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppLoader from "../../../../common/AppLoader/AppLoader";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import {
  getSegmentConfigConditions,
  addSegment,
  updateSegment,
  getSegmentDropdownList,
  getSegment,
  getSegmentConfig,
} from "@/services/clubDetails/segments.service";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import dropdownlistWithGroup from "@/components/common/dropdownlistWithGroup/dropdownlistWithGroup.vue";
export default {
  name: "CreateUpdateSegment",
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    "k-input": Input,
    AppDropdownGroup,
    datePicker: DatePicker,
    kdatetime: DateTimePicker,
    AppLoader,
    AppSnackBar,
    Breadcrumb,
    dropdowntree: DropDownTree,
    CreateUpdateSegmentModal,
    NumericTextBox,
    dropdownlistWithGroup,
  },
  data: () => ({
    sourceAndOr: [
      { id: "AND", text: "All" },
      { id: "OR", text: "Any" },
    ],
    currentTime: new Date(),

    sourceAndOrValue: "",
    mapConfig: [],
    addSegmentsModel: new AddSegmentModel(),
    payloadAddSegmentsModel: new AddSegmentModel(),
    isFirstColumn: false,
    isSecondColumn: false,
    nextCondition: "",
    configGroup: 0,
    isLoading: false,
    isGetSegmentLoading: false,
    index: 0,
    isNotify: false,
    notifyMessage: "Success",
    notifyType: "success",
    segmentChildConditionGroupDropdown: [],
    segmentChildConditionWhereDropdown: [],
    dayHoursWeeksYearDropDown: ["Hour", "Day", "Week", "Month", "Year"],
    dayHoursWeeksYearDropDownValue: [],
    dayHoursWeeksYearDropDownValueChnage: [],
    isWhereFirstValueDp: [],
    isWhereSecondValueDp: [],
    titleStr: "",
    isModalLoad: false,
    isChildDDLoading: false,
    segmentsConfigsData: [],
    isDropdownLoading: false,
    getSegmentConfigConditionsData: [],
    selectedSegment: null,
  }),
  props: {
    onConfirm: {
      type: Function,
      default: () => { },
    },
    isFromSegmentModal: {
      type: Boolean,
      default: false,
    },
    additionalFilterData: {
      type: Object,
      default: () => { },
    },
    typeOfAction: {
      type: String,
      default: "",
    },
    isFromFlowBuilder: {
      type: Boolean,
      default: false,
    },
    // selectedSegment: {
    //   type: String,
    //   default: null,
    // },
  },
  created() {
    this.getSegmentConfigConditionsFunc();
  },
  mounted() {
    this.sourceAndOrValue = this.sourceAndOr[0];
    this.getSegmentConfigs();

    if (!this.$route.query.segmentId || this.typeOfAction == "create") {
      this.addNewConditions(0);
      this.titleStr = "Create";
    }
    if (this.$route.query.segmentId || this.typeOfAction == "update") {
      this.getSegment();
      this.titleStr = "Edit";
    }
    this.addSegmentsModel.clubId = this.$route.params.id;
    this.addSegmentsModel.entityType = +this.$route.query.type || 2;
    this.addSegmentsModel.conditionType = this.sourceAndOr[0].id;
  },

  computed: {
    segmentLists() {
      return this.$store.state.segments.segmentListForDropdown
    },
    getSegmentConfigConditions() {
      return this.$store.state.segments.getSegmentConfigConditions;
    },
    segmentStoreData() {
      return this.$store.state.segments.segmentData;
    },
    isModalOpenForCreateSegment() {
      return this.$store.state.segments.segmentModalOpenClose;
    },
  },
  methods: {
    onChangeSelectSegment(e) {
      if (e.value?.id != 0) {
        this.selectedSegment = e.value;
        this.getSegment();
      } else {
        this.isAnySegmentSelected = false;
        this.selectedSegment = e.value;

        //reload all value for create state

        this.addSegmentsModel = new AddSegmentModel();
        this.payloadAddSegmentsModel = new AddSegmentModel();
        this.sourceAndOrValue = this.sourceAndOr[0];
        this.getSegmentConfigConditionsFunc();
        this.addNewConditions(0);
        this.addSegmentsModel.clubId = this.$route.params.id;
        this.addSegmentsModel.entityType = 2;
        this.addSegmentsModel.conditionType = this.sourceAndOr[0].id;
        this.titleStr = "Create";
      }
    },
    validateNumber(event, condition, jsonvalue, child = null) {
      let value = event.target.value;
      const numericValue = value.replace(/[^0-9]/g, "");
      if (!child) {
        console.log(condition[jsonvalue], "abc-009");
        condition[jsonvalue] = numericValue;
        console.log(condition[jsonvalue], "abc-009");
      } else {
        console.log(child, "abc-009");
        condition[child][jsonvalue] = numericValue;
        console.log(condition[child][jsonvalue], "abc-009");
      }
      // if (numericValue !== value) {
      //   event.target.value = numericValue;
      // }
    },
    SegmentModal() {
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", true);
    },
    getSegmentConfigConditionsFunc() {
      this.isLoading = true;

      let payload = {
        clubId: this.$route.params.id,
        configGroup: 0,
      };

      getSegmentConfigConditions(payload)
        .then((response) => {
          setTimeout(() => {
            this.getSegmentConfigConditionsData = response?.data?.data;
            this.isLoading = false;
            if (
              (!this.isFromSegmentModal && !this.$route?.query?.segmentId &&
                this.getSegmentConfigConditionsData?.length) || (this.typeOfAction == "create" && this.typeOfAction != "update")
            ) {
              this.onChangeMap(
                1,
                this.addSegmentsModel?.segmentGroupList[0]
                  ?.segmentConditionList[0],
                0
              );
            }
          }, 4000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.isGetSegmentLoading = false;
        });
    },

    async getSegmentConfigs() {
      this.$store.commit("SET_SEGMENTS_LOADER", true);
      await getSegmentConfig(this.$route.params.id)
        .then((response) => {
          this.$store.commit("SET_SEGMENTS_LOADER", false);
          this.$store.commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          this.segmentsConfigsData = response.data.data;
          this.$store.commit("SET_SEGMENTS_CONFIGS", response.data.data);
          if (response.data.data.items.length) {
            this.$store.commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            this.$store.commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          this.$store.commit("SET_SEGMENTS_LOADER", false);
        });
    },
    removeSegmentConditionList(event, condition, index, indexNew) {
      this.addSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.splice(index, 1);
      this.payloadAddSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.splice(index, 1);
    },

    onChangeSegmentConfigConditionValue(e, condition) {
      const resetChildCondition = (childCondition) => {
        Object.assign(childCondition, {
          isWhereFirstColumn: false,
          isWhereSecondColumn: false,
          isGroupFirstColumn: false,
          isGroupSecondColumn: false,
          whereNextCondition: "",
          groupNextCondition: "",
          whereConditionType: "",
          whereFirstValue: "",
          whereSecondValue: "",
          segmentConfigConditionGroup:
            childCondition?.segmentConfigConditionGroupDDL?.[0],
          segmentConfigConditionWhere: childCondition?.segmentConfigConditionWhereDDL?.[0]
        });
      };

      // Reset condition properties
      Object.assign(condition, {
        isWhereFirstValueD: false,
        isWhereSecondValueDp: false,
        firstValue: "",
        secondValue: "",
        segmentConfigCondition: e.value,
        isFirstColumn: e?.value?.firstColumn,
        isSecondColumn: e?.value?.secondColumn,
        nextCondition: e?.value?.nextCondition,
        configGroup: e?.value?.configGroup,
        segmentConfigurationConditionId: e?.value?.id,
      });

      this.emailError = "";

      // If segmentChildCondition exists, reset it and apply new filters
      if (condition?.segmentChildCondition) {
        resetChildCondition(condition.segmentChildCondition);

        const filterConditions = (group) =>
          this.getSegmentConfigConditionsData.filter(
            (x) => x.configGroup === group
          );

        condition.segmentChildCondition.segmentConfigConditionGroupDDL =
          filterConditions(8);
        condition.segmentChildCondition.segmentConfigConditionWhereDDL =
          filterConditions(9);

        if ([5, 6].includes(condition.configGroup)) {
          Object.assign(condition.segmentChildCondition, {
            segmentConfigConditionGroupId:
              condition?.segmentChildCondition?.segmentConfigConditionGroup?.id,
            segmentConfigConditionWhereId:
              condition?.segmentChildCondition?.segmentConfigConditionWhere?.id,
          });
        }
      }
    },

    onChangeCampaignWorkFlowDropdown(e, condition) {
      const { value } = e;
      console.log(value, "Abc-002");
      const childCondition = condition.segmentChildCondition;

      Object.assign(childCondition, {
        segmentConfigConditionGroup: value,
        segmentConfigConditionGroupId: value?.id || null,
        isGroupFirstColumn: value?.firstColumn || false,
        isGroupSecondColumn: value?.secondColumn || false,
        groupNextCondition: value?.nextCondition || "",
        groupFirstValue: "",
        groupSecondValue: "",
      });
    },

    onChangeCampaignWorkFlowSecondDropdown(e, condition, index) {
      console.log(e, "Abc-003");
      // Destructure the value from the event for easier access
      const {
        value = {},
        value: {
          id,
          firstColumn,
          secondColumn,
          nextCondition,
          firstValue,
          lastValue,
        } = {},
      } = e;

      // Update segmentConfigConditionWhere and other properties
      condition.segmentChildCondition.segmentConfigConditionWhere = value;
      condition.segmentChildCondition.segmentConfigConditionWhereId = id;
      condition.segmentChildCondition.isWhereFirstColumn = firstColumn;
      condition.segmentChildCondition.isWhereSecondColumn = secondColumn;
      condition.segmentChildCondition.whereNextCondition = nextCondition;

      // Update boolean flags based on 'X' value check
      condition.isWhereFirstValueD = firstValue === "X";
      condition.isWhereSecondValueDp = lastValue === "X";

      // Set default dropdown value for hours
      this.dayHoursWeeksYearDropDownValue[index] = "Hour";
      condition.whereConditionType = "Hour";

      // Set whereConditionType based on the conditions provided
      if (
        (firstColumn && lastValue !== "X") ||
        (secondColumn && firstValue !== "X")
      ) {
        condition.segmentChildCondition.whereConditionType =
          this.dayHoursWeeksYearDropDownValue[index];
      }

      // Reset where values
      condition.segmentChildCondition.whereFirstValue = "";
      condition.segmentChildCondition.whereSecondValue = "";
    },

    onChangeDayHoursWeeksYearDropdown(e, condition, index) {

      // Extract the value from the event for clarity and readability
      const { value } = e;

      // Update the whereConditionType on the condition's child segment
      condition.segmentChildCondition.whereConditionType = value;
      condition.whereConditionType = value;
      console.log("Abc-004", condition.segmentChildCondition.whereConditionType);
      // Update the corresponding index in the dropdown value arrays
      this.dayHoursWeeksYearDropDownValue[index] = value;
      this.dayHoursWeeksYearDropDownValueChnage[index] = value;
    },

    onChangeDayHoursWeeksYearForCreatedDropdown(e, condition, index) {
      condition.whereConditionType = e.value;
    },

    getDayHoursWeeksYearDropdown(e, condition, index) {
      return condition.whereConditionType;
    },

    getWhereTypeConditionDropdown(e, condition, index) {
      return condition?.segmentChildCondition?.whereConditionType;
    },

    onChangeGetDropdownListValue(e, condition, index) {
      condition.firstValue = e?.join(" UNION ALL ");
      e.forEach((element) => {
        if (element < 0) {
          condition.UserDefinedDropdownList.forEach((firstValueDropdownIdx) => {
            firstValueDropdownIdx.forEach((list) => {
              list.options.forEach((item) => {
                if (item.value < 0) {
                  item.disabled = true;
                }
              });
            });
          });
        }
      });
    },

    checkAndGetCustomFieldSourceByValue(val) {
      if (this.segmentsConfigsData && this.segmentsConfigsData.length > 0) {
        console.log(val, "eee1");
        return this.getCustomFieldSourceByValue(val);
      } else {
        this.$watch("segmentsConfigsData", (newVal, oldVal) => {
          console.log(val, "eee2");
          if (newVal && newVal.length > 0) {
            return this.getCustomFieldSourceByValue(val);
          }
        });
      }
    },

    getCustomFieldSourceByValue(val) {
      console.log(this.segmentsConfigsData, "1");
      let custom_fields = this.segmentsConfigsData;
      for (let i in custom_fields) {
        let searchHandler = custom_fields[i].options;
        for (let j in searchHandler) {
          if (searchHandler[j].value === val) {
            return searchHandler[j].configGroup;
          }
        }
      }
    },

    onChangeMap(e, condition, index) {
      console.log("Abc-005", e);
      // Set initial values for condition
      condition.segmentConfigId = e;
      condition.isWhereFirstValueD = false;
      condition.isWhereSecondValueDp = false;

      // Reset segmentChildCondition properties if it exists
      if (condition?.segmentChildCondition) {
        const childCondition = condition.segmentChildCondition;
        childCondition.isWhereFirstColumn = false;
        childCondition.isWhereSecondColumn = false;
        childCondition.isGroupFirstColumn = false;
        childCondition.isGroupSecondColumn = false;
        childCondition.whereNextCondition = "";
        childCondition.groupNextCondition = "";
        childCondition.whereSecondValue = "";
        childCondition.whereFirstValue = "";
        childCondition.segmentConfigConditionGroupId = 0;
        childCondition.segmentConfigConditionWhereId = 0;
        childCondition.whereConditionType = "";
      }

      // Reset values related to user input
      condition.firstValue = "";
      condition.secondValue = "";
      this.emailError = "";

      // Process based on the value of e
      const source = this.checkAndGetCustomFieldSourceByValue(e);
      this.mapConfig = this.mapConfig.filter((x) => x.column !== "item");

      if (e !== "Select Column") {
        // Filter segment config conditions based on source
        condition.segmentConfigConditionDDL =
          this.getSegmentConfigConditionsData.filter(
            (x) => x.configGroup === source
          );

        if (condition.segmentConfigConditionDDL.length > 0) {
          const [firstCondition] = condition.segmentConfigConditionDDL;

          condition.segmentConfigCondition = firstCondition;
          condition.isFirstColumn = firstCondition?.firstColumn;
          condition.isSecondColumn = firstCondition?.secondColumn;
          condition.nextCondition = firstCondition?.nextCondition;
          condition.configGroup = firstCondition?.configGroup;
          condition.segmentConfigurationConditionId = firstCondition?.id;
        }

        // Update segmentChildCondition if it exists
        if (condition?.segmentChildCondition) {
          const childCondition = condition.segmentChildCondition;

          // Filter group conditions for the child segment
          childCondition.segmentConfigConditionGroupDDL =
            this.getSegmentConfigConditionsData.filter(
              (x) => x.configGroup === 8
            );

          if (childCondition.segmentConfigConditionGroupDDL.length > 0) {
            const [firstGroupCondition] =
              childCondition.segmentConfigConditionGroupDDL;

            childCondition.segmentConfigConditionGroup = firstGroupCondition;
            childCondition.segmentConfigConditionGroupId =
              condition.isFirstColumn &&
                [5, 6, 7].includes(condition.configGroup)
                ? firstGroupCondition.id
                : 0;
            childCondition.isGroupFirstColumn =
              firstGroupCondition?.firstColumn;
            childCondition.isGroupSecondColumn =
              firstGroupCondition?.secondColumn;
            childCondition.groupNextCondition =
              firstGroupCondition?.nextCondition;
          }

          // Filter where conditions for the child segment
          childCondition.segmentConfigConditionWhereDDL =
            this.getSegmentConfigConditionsData.filter(
              (x) => x.configGroup === 9
            );

          if (childCondition.segmentConfigConditionWhereDDL.length > 0) {
            const [firstWhereCondition] =
              childCondition.segmentConfigConditionWhereDDL;

            childCondition.segmentConfigConditionWhere = firstWhereCondition;
            childCondition.segmentConfigConditionWhereId =
              condition.isFirstColumn &&
                [5, 6, 7].includes(condition.configGroup)
                ? firstWhereCondition.id
                : 0;
            childCondition.isWhereFirstColumn =
              firstWhereCondition?.firstColumn;
            childCondition.isWhereSecondColumn =
              firstWhereCondition?.secondColumn;
            childCondition.whereNextCondition =
              firstWhereCondition?.nextCondition;

            // Update where value flags based on the firstWhereCondition values
            this.isWhereFirstValueDp[0] =
              firstWhereCondition.firstValue === "X";
            this.isWhereSecondValueDp[0] =
              firstWhereCondition.lastValue === "X";

            // Set the dropdown value to 'Hour' by default
            this.dayHoursWeeksYearDropDownValue[index] = "Hour";
            condition.whereConditionType = "Hour"

            // Update whereConditionType if relevant conditions are met
            if (
              (childCondition.isWhereSecondColumn &&
                childCondition.whereSecondValue !== "X") ||
              (childCondition.isWhereFirstColumn &&
                childCondition.whereFirstValue !== "X")
            ) {
              childCondition.whereConditionType =
                this.dayHoursWeeksYearDropDownValue[index];
            }
          }
        }

        // Prepare and send a request to getSegmentDropdownList
        const model = {
          id: e,
          clubId: this.$route.params.id,
          entityType: +this.$route.query.type || 2,
        };
        this.isDropdownLoading = true;
        condition.userDefinedDropdownList = [];
        getSegmentDropdownList(model)
          .then((response) => {
            this.isDropdownLoading = false;
            condition.userDefinedDropdownList = response.data.data;
          })
          .catch((error) => {
            this.isDropdownLoading = false;
            // Handle error if needed
          });
      }

      // Check if the email field is present in mapConfig to enable saving
      if (this.mapConfig.some((x) => x.column === "email")) {
        this.canSave = true;
      }
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },

    getSegment() {
      this.isGetSegmentLoading = true;
      let payload = {
        clubId: this.$route.params.id,
        id: +this.$route.query.segmentId || this.additionalFilterData?.segmentId || this.selectedSegment?.id,
        searchQuery: "",
        entityType: this.$route.query.type || 2,
      };

      getSegment(payload)
        .then((response) => {
          this.addSegmentsModel = response.data.data;
          if (this.addSegmentsModel?.conditionType == "AND") {
            this.sourceAndOrValue = { id: "AND", text: "All" };
          }
          if (this.addSegmentsModel?.conditionType == "OR") {
            this.sourceAndOrValue = { id: "OR", text: "Any" };
          }

          this.isGetSegmentLoading = false;
        })
        .catch((error) => {
          this.isGetSegmentLoading = false;
        });
    },

    segmentGroupListAndCondition(conditionNew, indexNew) {
      conditionNew.conditionType = "And";
    },

    segmentGroupListOrCondition(conditionNew, indexNew) {
      conditionNew.conditionType = "Or";
    },

    segmentConditionListAndCondition(condition, index, conditionNew) {
      conditionNew?.segmentConditionList.forEach((element) => {
        element.conditionType = "And";
      });
    },

    segmentConditionListOrCondition(condition, index, conditionNew) {
      conditionNew?.segmentConditionList.forEach((element) => {
        element.conditionType = "Or";
      });
    },

    removeSegmentGroupConditionList($event, conditionNew, indexNew) {
      this.addSegmentsModel?.segmentGroupList?.splice(indexNew, 1);
      this.payloadAddSegmentsModel?.segmentGroupList?.splice(indexNew, 1);
    },

    addNewConditions(indexNew) {
      this.addSegmentsModel?.segmentGroupList.push({
        id: 0,
        // segmentId: 0,
        conditionType: "And",
        segmentConditionList: [],
      });
      this.addConditions(indexNew);
    },

    addConditions(indexNew) {
      console.log(indexNew, "ABc-006");
      // Access the current segmentGroup and its segmentConditionList using optional chaining
      const segmentGroup = this.addSegmentsModel?.segmentGroupList[indexNew];
      const conditionList = segmentGroup?.segmentConditionList;

      // Determine the condition type based on existing conditions
      const conditionType =
        conditionList && conditionList[1]
          ? conditionList[1].conditionType
          : "And";

      // Define the new condition object
      const newCondition = {
        id: 0,
        segmentId: 0,
        segmentConfigId: 0,
        segmentConfigurationConditionId: 0,
        condition: "",
        conditionType: conditionType,
        nextCondition: "",
        firstValue: "",
        secondValue: "",
        segmentChildCondition: {
          id: 0,
          segmentConditionId: 0,
          segmentConfigConditionGroupId: 0,
          groupCondition: "",
          groupNextCondition: "",
          groupFirstValue: "",
          groupSecondValue: "",
          segmentConfigConditionWhereId: 0,
          whereCondition: "",
          whereNextCondition: "",
          whereFirstValue: "",
          whereSecondValue: "",
          whereConditionType: "",
          segmentConfigConditionGroupDDL: [],
          segmentConfigConditionGroup: [],
          segmentConfigConditionWhereDDL: [],
          segmentConfigConditionWhere: [],
          isGroupFirstColumn: false,
          isGroupSecondColumn: false,
          isWhereFirstColumn: false,
          isWhereSecondColumn: false,
        },
      };

      // Push the new condition object to the segmentConditionList
      conditionList?.push(newCondition);
    },

    payloadGroupModelInit(indexNew) {
      this.payloadAddSegmentsModel?.segmentGroupList.push({
        id: 0,
        conditionType: "And",
        segmentConditionList: [],
      });
    },

    payloadModelInit(indexNew) {
      console.log(indexNew, "ABC-007");
      // Access the specified segment group and condition list using optional chaining
      const segmentGroup =
        this.payloadAddSegmentsModel?.segmentGroupList[indexNew];
      const conditionList = segmentGroup?.segmentConditionList;

      // Determine the condition type based on the existing conditions
      const conditionType =
        conditionList && conditionList[1]
          ? conditionList[1].conditionType
          : "And";

      // Define the new condition object
      const newCondition = {
        id: 0,
        segmentId: 0,
        segmentConfigId: 0,
        segmentConfigurationConditionId: 0,
        condition: "",
        conditionType: conditionType,
        nextCondition: "",
        firstValue: "",
        secondValue: "",
        segmentChildCondition: {
          id: 0,
          segmentConditionId: 0,
          segmentConfigConditionGroupId: 0,
          groupCondition: "",
          groupNextCondition: "",
          groupFirstValue: "",
          groupSecondValue: "",
          segmentConfigConditionWhereId: 0,
          whereCondition: "",
          whereNextCondition: "",
          whereFirstValue: "",
          whereSecondValue: "",
          whereConditionType: "",
          isGroupFirstColumn: false,
          isGroupSecondColumn: false,
          isWhereFirstColumn: false,
          isWhereSecondColumn: false,
        },
      };

      // Add the new condition to the segment condition list
      conditionList?.push(newCondition);
    },

    onChangeAndOr(event) {
      this.sourceAndOrValue = event.value;
      this.addSegmentsModel.conditionType = event.value.id;
    },

    cancelSegment() {
      this.$router.push(
        `/club-details/${this.$route.params.id}/campaign-segments?type=${this.$route.query.type}`
      );
    },

    onChangeDatePickerFirstValue(e, condition, index) {
      condition.firstValue = e.target.value;
    },

    onChangeDatePickerSecondValue(e, condition, index) {
      condition.secondValue = e.target.value;
    },

    onChangeDatePickerValue(e, condition, value) {
      condition[value] = e.target.value;
    },

    onChangeDatePickerWhereFirstValue(e, condition, index) {
      condition.isWhereFirstValueD = true;
      condition.segmentChildCondition.whereFirstValue = e.target.value;
    },

    onChangeDatePickerWhereSecondValue(e, condition, index) {
      condition.segmentChildCondition.whereSecondValue = e.target.value;
    },

    firstValueTextFieldOpen(event, condition, index) {
      const { isFirstColumn, configGroup, segmentConfigId } = condition || {};

      return (
        isFirstColumn &&
        configGroup !== 4 &&
        configGroup !== 3 &&
        configGroup !== 8 &&
        ![2, 5, 6, 7].includes(configGroup) &&
        ![4].includes(segmentConfigId)
      );
    },

    firstValueTextFieldNumberOpen(event, condition, index) {
      const { isFirstColumn, configGroup } = condition || {};
      return (
        isFirstColumn &&
        configGroup !== 4 &&
        (configGroup === 8 || configGroup === 3) &&
        ![2, 5, 6, 7].includes(configGroup)
      );
    },

    firstValueMultiSelectFieldOpen(event, condition, index) {
      const { isFirstColumn, configGroup } = condition || {};
      return (
        !this.isDropdownLoading &&
        isFirstColumn &&
        [2, 5, 6, 7].includes(configGroup)
      );
    },

    firstValueDatePickerFieldOpen(event, condition, index) {
      const { isFirstColumn, configGroup, firstValue } = condition || {};

      if (
        isFirstColumn &&
        configGroup === 4 &&
        ![2, 5, 6, 7].includes(configGroup)
      ) {
        condition.firstValue = firstValue || "";
      }

      return (
        isFirstColumn &&
        configGroup === 4 &&
        ![2, 5, 6, 7].includes(configGroup)
      );
    },

    SecondColumnValueTextFieldOpen(event, condition, index) {
      const { isSecondColumn, configGroup } = condition || {};
      return (
        isSecondColumn &&
        configGroup !== 4 &&
        configGroup !== 2 &&
        ![8, 3].includes(configGroup)
      );
    },

    SecondColumnValueNumberFieldOpen(event, condition, index) {
      const { isSecondColumn, configGroup } = condition || {};
      return (
        isSecondColumn &&
        configGroup !== 4 &&
        configGroup !== 2 &&
        [8, 3].includes(configGroup)
      );
    },

    secondValueDatePickerFieldOpen(event, condition, index) {
      const { isSecondColumn, configGroup, secondValue } = condition || {};

      if (isSecondColumn && configGroup === 4 && configGroup !== 2) {
        condition.secondValue = secondValue || "";
      }

      return isSecondColumn && configGroup === 4 && configGroup !== 2;
    },

    segmentChildConditionDropdownOpen($event, condition, index) {
      const { isFirstColumn, configGroup } = condition || {};
      return isFirstColumn && [5, 6, 7].includes(configGroup);
    },

    segmentChildConditionDropdownWhereDDLOpen($event, condition, index) {
      const { isFirstColumn, configGroup } = condition || {};
      return isFirstColumn && [5, 6, 7].includes(configGroup);
    },

    segmentChildConditionDropdownWhereFirstValueOpen($event, condition, index) {
      const { segmentChildCondition, isWhereFirstValueD } = condition || {};

      if (
        segmentChildCondition?.isWhereFirstColumn &&
        (isWhereFirstValueD ||
          segmentChildCondition?.segmentConfigConditionWhere?.firstValue ===
          "X")
      ) {
        segmentChildCondition.whereFirstValue =
          segmentChildCondition.whereFirstValue || "";
      }

      return (
        segmentChildCondition?.isWhereFirstColumn &&
        (isWhereFirstValueD ||
          segmentChildCondition?.segmentConfigConditionWhere?.firstValue ===
          "X")
      );
    },

    segmentChildConditionDropdownWhereSecondValueOpen(
      $event,
      condition,
      index
    ) {
      const { segmentChildCondition, isWhereSecondValueDp } = condition || {};
      const {
        isWhereSecondColumn,
        segmentConfigConditionWhere,
        whereSecondValue,
      } = segmentChildCondition || {};

      if (
        isWhereSecondColumn &&
        (isWhereSecondValueDp || segmentConfigConditionWhere?.lastValue === "X")
      ) {
        segmentChildCondition.whereSecondValue = whereSecondValue || "";
      }

      return (
        isWhereSecondColumn &&
        (isWhereSecondValueDp || segmentConfigConditionWhere?.lastValue === "X")
      );
    },

    payloadModify() {
      const { addSegmentsModel, payloadAddSegmentsModel } = this;

      // Copy top-level properties
      const topLevelProps = [
        "id",
        "clubId",
        "entityType",
        "segmentName",
        "conditionType",
        "finalSQL",
      ];
      topLevelProps.forEach((prop) => {
        payloadAddSegmentsModel[prop] = addSegmentsModel[prop];
      });

      // Copy segment group properties
      addSegmentsModel?.segmentGroupList.forEach((elementNew, index) => {
        this.payloadGroupModelInit(index);

        const groupProps = ["id", "conditionType"];
        groupProps.forEach((prop) => {
          payloadAddSegmentsModel.segmentGroupList[index][prop] =
            elementNew[prop];
        });

        // Copy segment condition properties
        elementNew.segmentConditionList?.forEach((condition, i) => {
          this.payloadModelInit(index);

          const conditionProps = [
            "id",
            "segmentId",
            "segmentConfigId",
            "segmentConfigurationConditionId",
            "condition",
            "conditionType",
            "nextCondition",
            "firstValue",
            "secondValue",
            "whereConditionType",
            "configGroup",
            "isFirstColumn",
            "isSecondColumn",
          ];
          conditionProps.forEach((prop) => {
            payloadAddSegmentsModel.segmentGroupList[
              index
            ].segmentConditionList[i][prop] = condition[prop];
          });

          // Copy child condition properties
          const childConditionProps = [
            "id",
            "segmentConditionId",
            "segmentConfigConditionGroupId",
            "groupCondition",
            "groupNextCondition",
            "groupFirstValue",
            "groupSecondValue",
            "segmentConfigConditionWhereId",
            "whereCondition",
            "whereNextCondition",
            "whereFirstValue",
            "whereSecondValue",
            "whereConditionType",
          ];
          const childCondition = condition?.segmentChildCondition || {};
          childConditionProps.forEach((prop) => {
            payloadAddSegmentsModel.segmentGroupList[
              index
            ].segmentConditionList[i].segmentChildCondition[prop] =
              childCondition[prop];
          });
        });
      });

      return payloadAddSegmentsModel;
    },

    validation() {
      let isValid = true;
      const { addSegmentsModel } = this;

      const notifyAndInvalidate = (message, type = "warning") => {
        this.showNotification(message, type);
        isValid = false;
      };

      const checkValue = (value, message, type = "error") => {
        if (value === "" || value === null || value === undefined) {
          notifyAndInvalidate(message, type);
        }
      };

      addSegmentsModel?.segmentGroupList.forEach((group) => {
        if (!group.conditionType) {
          notifyAndInvalidate(
            "ConditionGroup Type missing for one of your conditions."
          );
        }

        group?.segmentConditionList.forEach((condition) => {
          const {
            segmentChildCondition: child,
            segmentConfigId,
            conditionType,
            configGroup,
            firstValue,
            secondValue,
          } = condition;

          if (segmentConfigId === 11 || segmentConfigId === 12) return;

          // Child condition validations
          if (child) {
            if (child.isGroupFirstColumn) {
              checkValue(
                child.groupFirstValue,
                "Value missing for one of your conditions.",
                "error"
              );
            }

            if (child.isGroupSecondColumn) {
              checkValue(
                child.groupSecondValue,
                "Value missing for one of your conditions.",
                "error"
              );
            }

            if (
              child.isGroupFirstColumn &&
              child.isGroupSecondColumn &&
              child.groupFirstValue > child.groupSecondValue
            ) {
              notifyAndInvalidate(
                "First condition can't be greater than second condition.",
                "error"
              );
            }

            if (
              child.isWhereFirstColumn &&
              !condition.isWhereFirstValueD &&
              child.segmentConfigConditionWhere?.firstValue !== "X"
            ) {
              checkValue(
                child.whereFirstValue,
                "Value missing for one of your conditions.",
                "error"
              );
            }

            if (
              child.isWhereSecondColumn &&
              !condition.isWhereSecondValueDp &&
              child.segmentConfigConditionWhere?.lastValue !== "X"
            ) {
              checkValue(
                child.whereSecondValue,
                "Value missing for one of your conditions.",
                "error"
              );
            }

            if (
              child.isWhereFirstColumn &&
              child.isWhereSecondColumn &&
              child.whereFirstValue > child.whereSecondValue
            ) {
              notifyAndInvalidate(
                "First condition can't be greater than second condition.",
                "error"
              );
            }
          }

          // Main condition validations
          if (!segmentConfigId) {
            notifyAndInvalidate("segmentConfigId is missing.");
          }
          if (!conditionType) {
            notifyAndInvalidate(
              "Condition Type missing for one of your conditions."
            );
          }

          // Group-related validations
          if (condition.isFirstColumn) {
            if (![2, 5, 6, 7].includes(configGroup)) {
              checkValue(
                firstValue,
                "Value missing for one of your conditions."
              );
            }
            if (
              configGroup === 4 &&
              firstValue &&
              secondValue &&
              new Date(firstValue) > new Date(secondValue)
            ) {
              notifyAndInvalidate("Please input a valid time range.");
            }
          }

          if (condition.isSecondColumn) {
            checkValue(
              secondValue,
              "Value missing for one of your conditions."
            );
          }

          if ([2, 5].includes(configGroup) && !firstValue) {
            notifyAndInvalidate("Value missing for one of your conditions.");
          }
        });
      });

      // Segment name validations
      const segmentName = addSegmentsModel.segmentName.trim();
      if (!segmentName) {
        notifyAndInvalidate("Segment Name is required.");
      } else if (segmentName.length > 75) {
        notifyAndInvalidate("Segment name should not exceed 75 characters.");
      }

      return isValid;
    },
    getsegmentListForDropdown() {
      let payload = {
        clubId: this.$route.params.id,
        entityType: 2,
        limit: 1000,
      };
      this.$store.dispatch("getsegmentListForDropdown", payload);
    },
    addSegment() {
      if (!this.validation()) return;
      if ((!this.isFromSegmentModal && !this.$route.query.segmentId) || this.typeOfAction == "create") {
        if (this.selectedSegment?.id) {
          this.onConfirm({
            segmentId: this.selectedSegment?.id,
            segmentName: this.selectedSegment?.segmentName,
          });
          return;
        }
        this.isLoading = true;
        addSegment(this.payloadModify())
          .then((response) => {
            if (response) {
              // this.isLoading = false;
              if (response?.data?.data?.status == "error") {
                this.showNotification(
                  "Segments name already exists.",
                  "warning"
                );
                this.payloadAddSegmentsModel?.segmentGroupList.splice(
                  0,
                  this.payloadAddSegmentsModel?.segmentGroupList.length
                );
                this.isLoading = false;
                return;
              }
              this.showNotification("Segment created successfully.", "success");
              this.getsegmentListForDropdown();
              this.onConfirm({
                segmentId: response.data.data.id,
                segmentName: response.data.data.segmentName,
              });
              setTimeout(() => {
                this.isLoading = false;

                if (!this.isFromSegmentModal) {
                  this.cancelSegment();
                }
              }, 3000);
            }
          })
          .catch((error) => {
            this.showNotification(
              "Value missing for one of your conditions.",
              "error"
            );
            this.payloadAddSegmentsModel?.segmentGroupList.splice(
              0,
              this.payloadAddSegmentsModel?.segmentGroupList.length
            );
            this.isLoading = false;
          });
      }

      if (this.$route.query.segmentId || this.typeOfAction == "update") {
        this.isLoading = true;
        updateSegment(this.payloadModify())
          .then((response) => {
            if (response) {
              this.isLoading = false;
              this.showNotification("Segments Updated.", "success");
              this.onConfirm({
                segmentId: response.data.data.id,
                segmentName: response.data.data.segmentName,
              });
              this.payloadAddSegmentsModel = new AddSegmentModel();
              this.getSegment();
            }
          })
          .catch((error) => {
            this.showNotification(
              "Value missing for one of your conditions.",
              "error"
            );
            this.payloadAddSegmentsModel?.segmentGroupList.splice(
              0,
              this.payloadAddSegmentsModel?.segmentGroupList.length
            );
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
